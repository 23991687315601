import { createContext, FC, useEffect, useState } from 'react'
import { TTSEventType } from '@oribi/tts'
import storage from '../global/storage'
import tts from '../global/tts'
import { useTranslation } from 'react-i18next'
import Loader from '../components/Loader'

export enum Status {
  BOOTING = 'booting',
  IDLE = 'idle',
  LOADING = 'loading',
  SPEAKING = 'speaking',
  PAUSED = 'paused'
}

type TTSContextType = {
  status: Status
  lastError?: string
  hasTrack: { prev: boolean; next: boolean }
}

const defaultContext: TTSContextType = {
  status: Status.BOOTING,
  hasTrack: { prev: false, next: false }
}

export const TTSContext = createContext(defaultContext)

export const TTSProvider: FC = ({ children }) => {
  const [status, setStatus] = useState(Status.BOOTING)
  const [lastError, setLastError] = useState(undefined as string | undefined)
  const [hasTrack, setHasTrack] = useState(defaultContext.hasTrack)

  useEffect(() => {
    if (tts.hasFetchedVoices) {
      setStatus(Status.IDLE)
    }

    tts.addEventHandler(event => {
      switch (event.type) {
        case TTSEventType.VOICES_RECEIVED:
        case TTSEventType.END:
          setStatus(Status.IDLE)
          setHasTrack(defaultContext.hasTrack)
          break
        case TTSEventType.LOADING:
          setStatus(Status.LOADING)
          break
        case TTSEventType.START:
        case TTSEventType.RESUME:
        case TTSEventType.BOUNDARY:
          setHasTrack({
            prev: tts.hasPrevTrack,
            next: tts.hasNextTrack
          })
          setStatus(Status.SPEAKING)
          break
        case TTSEventType.PAUSE:
          setStatus(Status.PAUSED)
          break
        case TTSEventType.ERROR:
          setStatus(Status.IDLE)
          setLastError(event.message)
          setHasTrack(defaultContext.hasTrack)
          break
        case TTSEventType.VOICE_SELECTED:
          const [selectedLang] = (event.voice?.lang || '').split('-')
          const { language: currentLang } = storage.get('language')
          if (currentLang !== selectedLang) {
            storage.set({ language: selectedLang })
          }
          break
        default:
          break
      }
    })
  }, [])

  return (
    <TTSContext.Provider value={{ status, lastError, hasTrack }}>
      {children}
    </TTSContext.Provider>
  )
}

const TTS: FC = ({ children }) => {
  const { t } = useTranslation()

  return (
    <TTSProvider>
      <TTSContext.Consumer>
        {({ status }) => {
          if (status === Status.BOOTING) {
            return <Loader content={t('status_init_tts')} />
          }

          return children
        }}
      </TTSContext.Consumer>
    </TTSProvider>
  )
}

export default TTS
