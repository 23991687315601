import { Voice } from '@oribi/tts'
// import { useTranslation } from 'react-i18next'
// import Flag from 'react-world-flags'

/**
 * Flags should generally only be displayed if the "region" or dialect isn't the
 * "default" of the language. E.g. if we're displaying a list of Swedish voices
 * (lang: "sv", "sv-SE", or "sv-FI"), only the "special" region should have a
 * flag displayed next to it ("FI").
 *
 * Some languages are easy to identify since the default region is just an
 * uppercase version of the language (e.g. "fr-FR", "it-IT" and "fi-FI").
 * Others, such as "sv-SE", need manual intervention.
 *
 * @param language - "sv" in "sv-SE"
 * @param region - "SE" in "sv-SE"
 */
const isDefaultLangRegion = (language: string, region: string) => {
  const defaultRegions: { [lang: string]: string } = {
    sv: 'SE',
    ja: 'JP',
    he: 'IL',
    ko: 'KR',
    hi: 'IN',
    el: 'GR',
    cs: 'CZ',
    da: 'DK',
    nb: 'NO',
    nn: 'NO'
  }

  return (
    language === region.toLowerCase() || defaultRegions[language] === region
  )
}

type Props = {
  lang: Voice['lang']
  className?: string
}

const FlagOrNull = ({ lang, className = '' }: Props) => {
  // const { t } = useTranslation()

  const [language, country] = lang.split('-')
  if (!country || isDefaultLangRegion(language, country)) return null

  className = className
    .split(' ')
    .filter(x => !!x.trim())
    .concat('rounded', 'shadow-sm')
    .join(' ')

  return (
    //
    // react-world-flags slowed down the application too much, return null for now
    //
    // <Flag
    //   title={t(`country_${country}`)}
    //   alt={t(`country_${country}`)}
    //   code={country}
    //   fallback={<></>}
    //   height='16'
    //   className={className}
    // />'
    null
  )
}

export default FlagOrNull
