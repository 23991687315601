import { useState, useRef } from 'react'
import { User } from '../context/User'
import { isValidEmail } from '@oribi/auth'
import storage from '../global/storage'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserSecret, faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import InputGroup from 'react-bootstrap/InputGroup'

interface SimulateLogInProps {
  onSuccess: (user: User) => void
}

const Simulator = (props: SimulateLogInProps) => {
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState('')
  const input = useRef<HTMLInputElement>(null)

  const handleInput = () => {
    if (isValidEmail(email)) {
      const user = { email: email, id: email }
      storage.setLocal('simulatedUser', user)
      props.onSuccess(user)
    }
  }

  return (
    <>
      <Button variant='secondary' onClick={() => setOpen(true)}>
        <FontAwesomeIcon className='mr-1' icon={faUserSecret} /> Simulera e-post
      </Button>

      <Modal
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={open}
        onHide={() => setOpen(false)}
        backdrop='static'
        onShow={() => input.current?.focus()}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Simulera e-post
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={e => {
              e.preventDefault()
              handleInput()
            }}
          >
            <Form.Group controlId='formBasicEmail'>
              <Form.Label>
                Skriv in en e-postadress här om du vill testa licenshanteringen
                med ett annat konto.
              </Form.Label>
              <InputGroup>
                <Form.Control
                  ref={input}
                  type='email'
                  placeholder='Valfri e-postadress'
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value)
                  }}
                />
                <InputGroup.Append>
                  <Button disabled={!email} type='submit'>
                    <FontAwesomeIcon icon={faSignInAlt} />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
              <Form.Text className='text-muted'>
                Observera att du inte kommer åt användarens inställningar och
                att denna funktion endast är tillgänglig i Rook.
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Simulator
