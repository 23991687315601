import { faCommentDots } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Form from 'react-bootstrap/Form'
import storage, { Store } from '../../global/storage'
import { capitalize } from '@oribi/tts'
import { useContext } from 'react'
import { StorageContext } from '../../context/Storage'
import { useTranslation } from 'react-i18next'
import InputGroup from 'react-bootstrap/InputGroup'

const TTSLangSwitcher = ({ label }: { label?: string }) => {
  const { store } = useContext(StorageContext)
  const { t } = useTranslation()

  const languages = store.languages as Store['languages']

  const langOptions = Object.keys(languages).map(lang => ({
    key: lang,
    text: capitalize(t(lang)),
    value: lang
  }))

  return (
    <Form.Group controlId='tts-lang-select'>
      {label && <Form.Label>{label}</Form.Label>}
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text id='tts-lang-icon'>
            <FontAwesomeIcon icon={faCommentDots} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          aria-labelledby='tts-lang-icon'
          as='select'
          custom
          value={store.language}
          onChange={({ target: { value: language } }) => {
            storage.set({ language })
          }}
        >
          {langOptions.map(({ key, text, value }) => (
            <option value={value} key={key}>
              {text}
            </option>
          ))}
        </Form.Control>
      </InputGroup>
    </Form.Group>
  )
}

export default TTSLangSwitcher
