import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { messageParent } from '@oribi/office-js'
import { loginRedirect, logOut } from '@oribi/auth'
import Loader from '../components/Loader'
import { manifest } from '../global/app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const { azureClientId } = manifest

const LogIn = () => {
  useEffect(() => {
    Office.onReady().then(() => {
      loginRedirect({
        clientId: azureClientId,
        onSuccess: response => {
          messageParent(response)
        },
        onError: error => {
          messageParent(error.errorMessage)
        }
      })
    })
  }, [])

  return <Loader content='Loggar in...' />
}

const LogOut = () => {
  useEffect(() => {
    Office.onReady().then(() => {
      logOut(azureClientId, window.location.origin + '/dialog')
    })
  }, [])

  return <Loader content='Loggar ut...' />
}

const LogOutComplete = () => {
  useEffect(() => {
    Office.onReady().then(() => {
      // No particular message. Any message at all tells the parent
      // page to close the dialog.
      messageParent('logout complete')
    })
  }, [])

  return (
    <div className='d-flex flex-column min-vh-100 justify-content-center align-items-center'>
      <FontAwesomeIcon icon={faCheck} />
    </div>
  )
}

const Dialog = () => {
  // Check url param to determine which part of auth flow to run
  // /dialog closes dialog
  // /dialog/login signs user in
  // /dialog/logout signs user out
  const { id } = useParams<{ id: string }>()

  if (id === 'login') return <LogIn />
  if (id === 'logout') return <LogOut />
  return <LogOutComplete />
}

export default Dialog
