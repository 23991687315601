import { useContext } from 'react'
import storage from '../../global/storage'
import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'
import { StorageContext } from '../../context/Storage'
import Toggle from './Toggle'

type Props = {
  compact?: boolean
}

const TalkingKeyboardSwitch = ({ compact }: Props) => {
  const { store } = useContext(StorageContext)
  const { t } = useTranslation()

  return (
    <Toggle
      setting='writing_support'
      title={t('writing-support')}
      compact={compact}
    >
      <Form className='mt-3'>
        <Form.Check
          disabled={!store.writing_support}
          id='checkbox-letter-label'
          checked={!!store.speak_letter}
          onChange={({ target: { checked } }) => {
            storage.set({ speak_letter: !!checked })
          }}
          label={t('checkbox-letter-label')}
          custom
        />
        <Form.Check
          disabled={!store.writing_support}
          id='checkbox-word-label'
          checked={!!store.speak_word}
          onChange={({ target: { checked } }) => {
            storage.set({ speak_word: !!checked })
          }}
          label={t('checkbox-word-label')}
          custom
        />
        <Form.Check
          disabled={!store.writing_support}
          id='checkbox-sentence-label'
          checked={!!store.speak_sentence}
          onChange={({ target: { checked } }) => {
            storage.set({ speak_sentence: !!checked })
          }}
          label={t('checkbox-sentence-label')}
          custom
        />
      </Form>
    </Toggle>
  )
}

export default TalkingKeyboardSwitch
