type Props = { style?: React.CSSProperties; className?: string }

const AppIcon = ({ style, className }: Props) => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 128 128'
    xmlSpace='preserve'
    width='128'
    height='128'
    style={style}
    className={className}
  >
    <g>
      <path
        style={{ fill: '#27AE60' }}
        d='M64,17.5c-25.7,0-46.5,20.8-46.5,46.5c0,9.1,2.7,17.6,7.2,24.8l-7.2,21.7l21.7-7.2c7.2,4.6,15.7,7.2,24.8,7.2
    c25.7,0,46.5-20.8,46.5-46.5C110.5,38.3,89.7,17.5,64,17.5z M64,27.2c4.3,0,7.8,3.5,7.8,7.8c0,4.3-3.5,7.8-7.8,7.8
    s-7.8-3.5-7.8-7.8C56.2,30.7,59.7,27.2,64,27.2z M64,98.9c-19.3,0-34.9-15.6-34.9-34.9c0-15.5,10.1-28.6,24-33.1
    c-0.5,1.3-0.8,2.6-0.8,4.1c0,2.9,1.1,5.5,2.8,7.5C46.7,45.9,40.8,54.3,40.8,64c0,12.8,10.4,23.2,23.2,23.2S87.2,76.8,87.2,64
    c0-9.7-6-18.1-14.5-21.5c1.7-2,2.8-4.6,2.8-7.5c0-1.4-0.3-2.8-0.8-4.1c13.9,4.6,24,17.7,24,33.1C98.9,83.3,83.3,98.9,64,98.9z'
      />
    </g>
  </svg>
)

export default AppIcon
