import React, { useState } from 'react'
import CommandBar from './CommandBar'
import style from './Layout.module.css'
import { Trans, useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import BrowserAction from './BrowserAction'

export type View = {
  menuItem: string
  icon?: IconProp
  render: (visible: boolean) => React.ReactNode
  miniPlayer?: boolean
  onOpen?: () => void
}

type Props = {
  views: View[]
  defaultActiveIndex?: number
}

const Layout = (props: Props) => {
  const { views, defaultActiveIndex } = props

  const { t } = useTranslation()
  const [mainView] = views
  const [activeView, setActiveView] = useState(mainView)

  const dictionaryView = views.find(
    ({ menuItem }) => menuItem === 'dictionary-menu-item'
  )

  if (activeView === undefined) {
    throw new Error(`No View at defaultActiveIndex ${defaultActiveIndex}`)
  }

  const activeViewIsDictionary = activeView === dictionaryView

  return (
    <div id='sidebar-inner' className={style.wrapper}>
      <nav role='navigation' className={style.nav}>
        <div className={style.player}>
          {!!activeView.miniPlayer && <CommandBar mini />}
        </div>
        <ButtonGroup className='ml-3'>
          {views.map((view, i) => {
            const isActive = view === activeView

            const Content = () => {
              if (view === mainView) return <BrowserAction />
              if (view.icon)
                return (
                  <>
                    <FontAwesomeIcon icon={view.icon} />
                    <span className='sr-only'>{t(view.menuItem)}</span>
                  </>
                )

              return <>{t(view.menuItem)}</>
            }

            return (
              <Button
                variant='light'
                title={t(view.menuItem)}
                active={isActive}
                key={i}
                onClick={() => {
                  setActiveView(view)
                  if (view.onOpen) view.onOpen()
                }}
              >
                <Content />
              </Button>
            )
          })}
        </ButtonGroup>
      </nav>
      <main
        role='main'
        className={`${style.main} d-flex flex-column flex-nowrap`}
      >
        {!activeViewIsDictionary && activeView.render(true)}
        {!!dictionaryView && dictionaryView.render(activeViewIsDictionary)}
      </main>
      <footer className={style.footer}>
        <Trans i18nKey='footer-text'>
          <a
            href='https://oribi.se'
            className='link'
            target='_blank'
            rel='noreferrer'
          >
            Oribi
          </a>
        </Trans>
      </footer>
    </div>
  )
}

export default Layout
