import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import AppIcon from '../../components/AppIcon'

type Props = {
  onSuccess: () => void
}

type InlineStyles = {
  [key: string]: React.CSSProperties
}
const inlineStyles: InlineStyles = {
  wrapper: {
    background:
      'linear-gradient(rgba(255,255,255, 0.5), rgba(255,255,255, 0.5)), url(/assets/backgrounds/bg-blobs.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  }
}

const OnboardingView = ({ onSuccess }: Props) => {
  const [index, setIndex] = useState(0)
  const { t } = useTranslation()

  const steps = [
    {
      heading: t('onboarding-welcome-heading'),
      text: t('onboarding-welcome-text')
    },
    {
      heading: t('onboarding-reading-heading'),
      text: t('onboarding-reading-text')
    },
    {
      heading: t('onboarding-language-heading'),
      text: t('onboarding-language-text')
    },
    {
      heading: t('onboarding-highlighter-heading'),
      text: t('onboarding-highlighter-text')
    }
  ]

  const handleSelect = (selectedIndex: number) => {
    selectedIndex < steps.length ? setIndex(selectedIndex) : setIndex(0)
  }

  const lastStep = index === steps.length - 1

  return (
    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
      <div className='onboarding-header'>
        <div className='onboarding-header-heading'>
          {' '}
          {t('onboarding-header-heading')}
        </div>
        <Button
          className='end-onboarding'
          variant='link'
          size='lg'
          onClick={() => onSuccess()}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
      <div id='slider' className='slider' style={inlineStyles.wrapper}>
        <AppIcon className='onboarding-logo mx-auto d-block' />

        <div className='slider-content'>
          <Carousel
            onSelect={handleSelect}
            activeIndex={index}
            controls={false}
            pause={false}
            interval={null}
          >
            {steps.map((step, key) => (
              <Carousel.Item key={key}>
                <div className='onboarding-text-and-heading p-3'>
                  <h2 className='mb-3'>{step.heading}</h2>
                  <p className='mb-0'>{step.text}</p>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
          <Button
            className='onboarding-big-button'
            onClick={() => (lastStep ? onSuccess() : handleSelect(index + 1))}
            variant='link'
            size='lg'
            active
          >
            {lastStep
              ? t('onboarding-done-button')
              : t('onboarding-next-button')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default OnboardingView
