import { UserStorage } from '@oribi/auth'

export type Store = {
  writing_support: boolean
  language: string
  languages: {
    [lang: string]: {
      voiceURI: string
      speed?: number
    }
  }
  speed: number
  speed_compact: boolean
  speak_letter: boolean
  speak_word: boolean
  speak_sentence: boolean
  highlighting: boolean
  license: any
  trial_start: any
  trial_expired: any
  school_id: any
  license_key: any
  language_detection: boolean
  language_detection_compact?: boolean
  highlighting_compact?: boolean
}

export const defaultStore: Store = {
  writing_support: false,
  language: 'sv',
  languages: {
    sv: {
      voiceURI: 'elin'
    },
    en: {
      voiceURI: 'graham'
    }
  },
  speed: 1,
  speed_compact: true,
  speak_letter: true,
  speak_word: true,
  speak_sentence: true,
  highlighting: true,
  license: null,
  trial_start: null,
  trial_expired: null,
  school_id: null,
  license_key: null,
  language_detection: true
}

const storage = new UserStorage({
  app: 'Oribi Speak',
  defaults: defaultStore
})

// @ts-ignore
window.storage = storage

export default storage
