import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { OnboardingContext } from '../../context/Onboarding'
import Button from 'react-bootstrap/Button'

const StartOnboardingBtn = () => {
  const { t } = useTranslation()
  const { setOnboarded } = useContext(OnboardingContext)
  const startOnboarding = () => setOnboarded(false)

  return (
    <Button variant='secondary' onClick={startOnboarding}>
      {t('start_onboarding')}
    </Button>
  )
}

export default StartOnboardingBtn
