import React from 'react'
import { getDocumentBody, Selection } from '@oribi/office-js'
import {
  getTextToSpeak,
  TTSMode,
  getVoiceFromURI,
  getVoiceGender
} from '@oribi/tts'
import storage, { defaultStore, Store } from '../global/storage'
import tts from '../global/tts'
import { FatalError } from '../context/Error'
import { StorageEventHandler } from '@oribi/auth/dist/components/storage'

type Props = {
  onError: (error: Error | FatalError) => void
}

class TalkingKeyboard extends React.Component<Props> {
  active = false
  audio = new Audio()
  prevSelection: Selection = { start: 0, length: 0 }
  prevDocText = ''
  writing_support = defaultStore.writing_support

  addEventListener = (): Promise<Office.AsyncResult<void>> => {
    this.active = true

    return new Promise(resolve => {
      try {
        Office.context.document.addHandlerAsync(
          Office.EventType.DocumentSelectionChanged,
          this.handleSelectionChange,
          result => resolve(result)
        )
      } catch (e) {
        this.props.onError(e as Error)
      }
    })
  }

  removeEventListener = (): Promise<Office.AsyncResult<void>> => {
    this.active = false

    return new Promise(resolve => {
      try {
        Office.context.document.removeHandlerAsync(
          Office.EventType.DocumentSelectionChanged,
          {
            handler: this.handleSelectionChange as any
          },
          result => resolve(result)
        )
      } catch (e) {
        this.props.onError(e as Error)
      }
    })
  }

  componentDidMount = () => {
    // Try to limit the event listener to only do its thing when the sidebar
    // is blurred

    /*
     * Consider using these event handlers if document.hasFocus() doesn't work
     * on select devices or browsers, native Word.
    window.addEventListener('blur', () => {
      if (this.writing_support) {
        this.addEventListener()
      } else {
        this.removeEventListener()
      }
    })
    window.addEventListener('focus', () => {
      this.removeEventListener()
    })
    */

    // Sync props and user storage
    storage
      .getAsync('writing_support')
      .then(properties => properties as Store)
      .then(({ writing_support }) => {
        this.writing_support = writing_support
      })

    storage.addOnChangedListener(this.handleStorageChange)
  }

  componentDidUpdate = () => {
    if (this.writing_support === this.active) {
      return
    } else if (this.writing_support) {
      this.addEventListener()
    } else {
      this.removeEventListener()
    }
  }

  handleStorageChange: StorageEventHandler = ({ property, newValue }) => {
    if ('writing_support' === property) {
      this.writing_support = newValue as boolean
      if (this.writing_support) {
        this.addEventListener()
      } else {
        this.removeEventListener()
      }
    }
  }

  handleSelectionChange = (event: Office.DocumentSelectionChangedEventArgs) => {
    // Only proceed if user wants writing support and DOM document (sidebar)
    // is blurred (focus is likely in Word document)
    if (!this.writing_support || document.hasFocus()) return

    // Stop listening for events
    // (getDocumentBody triggers selection events)
    this.removeEventListener().then(() => {
      getDocumentBody().then(({ text, selection }) => {
        // Start listening for events again
        this.addEventListener()

        // Don't speak if undefined selection or selection range
        if (!selection) return

        // Only speak keyboard input if seletion changed exactly 1
        const shouldProceed =
          selection.start - this.prevSelection.start === 1 &&
          selection.length === 0 &&
          text !== this.prevDocText

        // Done checking, set last selection to current
        this.prevSelection = selection
        this.prevDocText = text

        if (!shouldProceed) return

        // Destructure user storage properties into TextGetterOptions
        const {
          speak_letter: speakLetter,
          speak_word: speakWord,
          speak_sentence: speakSentence
        } = storage.get('speak_letter', 'speak_word', 'speak_sentence')

        // Let @oribi/tts determine which text to speak
        const textToSpeak = getTextToSpeak(TTSMode.WRITING, text, selection, {
          preferences: { speakLetter, speakWord, speakSentence }
        })

        // Text might be surrounded by whitespace
        const trimmed = textToSpeak.trim()
        if (trimmed.length === 0) return

        const isLetter = trimmed.length === 1
        if (isLetter) return this.echo(trimmed.toLowerCase().charCodeAt(0))

        // Prefer a local voice if text consists of only a single word
        const preferLocalService = trimmed.split(/\s/g).length === 1

        if (tts.isSpeaking) tts.stop()

        const {
          language: lang,
          languages,
          speed
        } = storage.get('language', 'speed', 'languages') as Store
        const voiceURI = languages[lang].voiceURI

        tts.speak(textToSpeak, {
          lang,
          voiceURI,
          speed,
          preferLocalService
        })
      })
    })
  }

  echo = (charCode: number): void => {
    if (tts.isSpeaking) tts.stop()

    const { language: lang, languages } = storage.get(
      'language',
      'speed',
      'languages'
    ) as Store
    const voiceURI = languages[lang].voiceURI

    const voice = getVoiceFromURI(voiceURI, tts.voices)

    let filepath = `/assets/audio/letters/${lang}/${charCode}.mp3`

    if (voice !== undefined && lang === 'sv') {
      filepath = `/assets/audio/letters/${lang}/${getVoiceGender(
        voice
      )}/${charCode}.mp3`
    }

    this.audio.src = filepath
    const playPromise = this.audio.play()

    if (playPromise !== undefined) {
      playPromise.then(function () {}).catch(function (error) {})
    }
  }

  render = () => {
    return <></>
  }
}

export default TalkingKeyboard
