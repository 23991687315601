import { DocumentBody } from '@oribi/office-js'
import { useRef, useEffect, useState, useContext } from 'react'
import { Alert, Form } from 'react-bootstrap'
import { HostContext } from '../../context/Host'
import { TextTarget, setCurrentTextTarget } from '../../global/tts'

let src: string
switch (process.env.REACT_APP_CONTEXT) {
  case 'production':
    src = 'https://lex.oribi.se'
    break
  case 'localhost':
    src = 'http://localhost:3003'
    break
  default:
    src = 'https://dev--orilex.netlify.app'
    break
}

type Props = { visible?: boolean }

export const getDictionarySelection: () => Promise<DocumentBody> = () => {
  return new Promise(resolve => {
    const iframe = document.getElementById(
      'dictionary-iframe'
    ) as HTMLIFrameElement
    if (!iframe || !iframe.contentWindow) return resolve({ text: '' })

    const timeout = setTimeout(() => {
      resolve({ text: '' })
      window.removeEventListener('message', handleIframeMessage)
    }, 400)

    const handleIframeMessage = (event: MessageEvent) => {
      // Only handle messages from dictionary iframe
      if (event.origin !== src || typeof event.data !== 'string') return

      const [key, text = ''] = event.data.split('=')
      if (key === 'selectionText') {
        clearTimeout(timeout)
        window.removeEventListener('message', handleIframeMessage)
        return resolve({ text })
      }
    }
    window.addEventListener('message', handleIframeMessage)

    iframe.contentWindow.postMessage('postSelectionText', '*')
  })
}

const Dictionary = (props: Props) => {
  const iframe = useRef<HTMLIFrameElement>(null)

  const [iframeLoaded, setIframeLoaded] = useState(false)
  const [unavailable, setUnavailable] = useState(false)

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      setUnavailable(true)
    }, 5000)

    const handleIframeLoaded = () => {
      clearTimeout(timeout)
      setIframeLoaded(true)

      // Introduce yourself to the dictionary
      iframe.current?.contentWindow?.postMessage('host=osl', src)
    }

    const handleIframeMessage = (event: MessageEvent) => {
      // Only handle messages from dictionary iframe
      if (event.origin !== src) return

      if (event.data === 'DOMContentLoaded') {
        handleIframeLoaded()
      }

      if (event.data === 'selectionChange') {
        setCurrentTextTarget(TextTarget.DICTIONARY)
      }
    }

    window.addEventListener('message', handleIframeMessage)
    return () => {
      if (timeout !== null) clearTimeout(timeout)
      window.removeEventListener('message', handleIframeMessage)
    }
  }, [])

  const { getBody } = useContext(HostContext)

  useEffect(() => {
    document.addEventListener('dictionaryOpen', () => {
      getBody().then(({ text, selection }) => {
        if (text && selection) {
          const { start, length } = selection
          const selectedText = text.substring(start, start + length)
          // Introduce yourself to the dictionary
          iframe.current?.contentWindow?.postMessage(
            'lookupWord=' + selectedText,
            src
          )
        }
      })
    })
  }, [getBody])

  const wrapperStyle = {
    margin: '-1rem',
    overflow: 'hidden'
  }

  const frameStyle = {
    width: '100%',
    border: 'none',
    display: iframeLoaded ? 'block' : 'none'
  }

  const { visible = true } = props

  return (
    <div
      style={wrapperStyle}
      className={`flex-grow-1 flex-column flex-nowrap ${
        visible ? 'd-flex' : 'd-none'
      }`}
    >
      {!iframeLoaded && !unavailable && (
        <div style={{ margin: 12 }}>
          <Form>
            <Form.Control placeholder='Vänta...' disabled />
          </Form>
        </div>
      )}

      {unavailable && !iframeLoaded && (
        <Alert variant='danger' className='m-3'>
          <Alert.Heading>Ordboken ej tillgänlig</Alert.Heading>
          Servern otillgänglig. Försök igen senare och kontakta Oribi om
          problemet kvarstår.
          {process.env.REACT_APP_CONTEXT === 'localhost' && (
            <>
              <br />
              Kontrollera att servern snurrar på{' '}
              <a target='_blank' rel='noreferrer' href={src}>
                {src}
              </a>
              .
            </>
          )}
        </Alert>
      )}

      <iframe
        id='dictionary-iframe'
        ref={iframe}
        src={src}
        title='Oribi Speak Ordbok'
        allowFullScreen={false}
        width={window.innerWidth}
        className='flex-grow-1'
        style={frameStyle}
        tabIndex={0}
      ></iframe>
    </div>
  )
}

export default Dictionary
