import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationSV from './_locales/sv/messages.json'
import translationEN from './_locales/en/messages.json'
import manifest from './_manifest.json'
import storage from './global/storage'

const [defaultLang] = manifest.common.defaultLocale.split('-')
const preferredLang = storage.getLocal('UI-language')

const resources = {
  sv: {
    translation: translationSV
  },
  en: {
    translation: translationEN
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: preferredLang || defaultLang,
  keySeparator: false,
  interpolation: {
    escapeValue: false
  },
  react: {
    transKeepBasicHtmlNodesFor: ['a']
  }
})

// Async check Office UI language and use it if user hasn't changed language
if (!preferredLang) {
  Office.onReady(({ host }) => {
    const { displayLanguage } = Office.context
    if (typeof displayLanguage === 'string') {
      const [officeLang] = displayLanguage.toLowerCase().split('-')
      if (Object.keys(resources).includes(officeLang)) {
        i18n.changeLanguage(officeLang)
      }
    } else if (host === null) {
      i18n.changeLanguage('sv')
    }
  })
}

export default i18n
