// import { displayDialogAsync } from '@oribi/office-js'
import { useContext, useEffect } from 'react'
import { createContext, useState, FC } from 'react'
import Button from 'react-bootstrap/Button'
// import { requestGraphUser } from '@oribi/auth'
import { useTranslation } from 'react-i18next'
import storage, { Store } from '../global/storage'
import Loader from '../components/Loader'
import SignIn from '../SignIn'
import { HostContext } from './Host'
import { requestGraphUser } from '@oribi/auth'
import { StorageContext } from './Storage'

/**
 * If id is undefined, we're still trying to log in.
 * If null, user isn't logged in.
 */
export type User = {
  id?: string | null
  email: string
}

type UserContextType = {
  user: User
  synced: boolean
  setUser: (newUser: User) => void
}

export const defaultUserContext: UserContextType = {
  user: { email: '' },
  synced: false,
  setUser: () => {}
}

export const UserContext = createContext(defaultUserContext)

export const isUser = (user: any): boolean => {
  try {
    const keys = Object.keys(user)

    return (
      keys.length === 2 &&
      keys.includes('email') &&
      keys.includes('id') &&
      typeof user.email === 'string' &&
      ((typeof user.id === 'string' && user.email.length > 0) ||
        user.id === undefined ||
        user.id === null)
    )
  } catch {
    return false
  }
}

export const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState(defaultUserContext.user)
  const [synced, setSynced] = useState(defaultUserContext.synced)
  const { host } = useContext(HostContext)
  const { setStore } = useContext(StorageContext)

  useEffect(() => {
    const handleSignedIn = (newUser: User) => {
      setUser(newUser)
    }

    const handleSignedOut = () => {
      setUser({
        id: null,
        email: ''
      })
    }

    // Only run sign in flow if user ID is undefined
    if (user.id !== undefined) {
      // If ID isn't null, init user settings
      if (user.id !== null) {
        storage.init(user.id).then(store => {
          setStore({ ...(store as Store) })
          setSynced(true)
        })
      }
      return
    }

    if (host === 'Standalone') {
      const testUser: User = {
        email: 'testuser@oribi.se',
        id: 'NO_SYNC'
      }
      storage.setLocal('simulatedUser', testUser)
      return handleSignedIn(testUser)
    }

    // If simulated user is set, skip actual sign in in flow and use stored user
    const simulatedUser: User | null = storage.getLocal('simulatedUser')

    if (simulatedUser !== null && isUser(simulatedUser)) {
      return handleSignedIn(simulatedUser)
    }

    const storedAccessToken = storage.getLocal('msalAccessToken')

    if (!!storedAccessToken && host !== null) {
      // Try to log in again
      requestGraphUser(storedAccessToken)
        .then(({ success, user }) => {
          if (!success || !user) throw new Error('unsuccessful graph request')

          const { id, email } = user
          if (!isUser({ id, email })) throw new Error('invalid graph user')

          setUser({ id, email })
        })
        .catch(() => {
          handleSignedOut()
        })
    } else {
      handleSignedOut()
    }
  }, [user.id, host, setStore])

  return (
    <UserContext.Provider value={{ user, setUser, synced }}>
      {children}
    </UserContext.Provider>
  )
}

export const LogOutBtn = () => {
  const { t } = useTranslation()

  if (logOut === undefined) return <p>Ingen funktino....</p>

  return (
    <Button variant='secondary' onClick={() => logOut()}>
      {t('settings-account-button')}
    </Button>
  )
}

const UserAccount: FC = ({ children }) => {
  const { t } = useTranslation()

  return (
    <UserProvider>
      <UserContext.Consumer>
        {({ user: { id }, synced }) => {
          if (id === undefined) {
            return <Loader content={t('status_signing_in')} />
          }

          if (id === null) return <SignIn />

          if (!synced) return <Loader content={t('status_syncing_storage')} />

          return children
        }}
      </UserContext.Consumer>
    </UserProvider>
  )
}

export default UserAccount

export const logOut = () => {
  storage.setLocal('msalAccessToken', null)
  storage.setLocal('simulatedUser', null)
  window.location.reload()
}
