import { getTextToSpeak, TTSMode, SpeakerOptions } from '@oribi/tts'
import { useContext } from 'react'
import { StorageContext } from '../context/Storage'
import { Store } from '../global/storage'
import { Status, TTSContext } from '../context/TTS'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStepBackward,
  faStepForward,
  faPlay,
  faPause,
  faStop
} from '@fortawesome/free-solid-svg-icons'
import Spinner from 'react-bootstrap/Spinner'
import tts, { currentTextTarget, TextTarget } from '../global/tts'
import { HostContext } from '../context/Host'
import { DocumentBody } from '@oribi/office-js'
import { getDictionarySelection } from '../Sidebar/Dictionary'

type Props = {
  mini?: boolean
}

let currentSelection = 0
let currentSelectionLength = 0

const CommandBar = ({ mini }: Props) => {
  const { status } = useContext(TTSContext)
  const { getBody } = useContext(HostContext)
  const store = useContext(StorageContext).store as Store
  const speed = store.speed as number

  const handlePlayClick = async () => {
    if (status === Status.LOADING) return tts.stop()

    const body: DocumentBody = await new Promise(resolve => {
      if (currentTextTarget === TextTarget.SELF) {
        const text = document.getSelection()?.toString() || ''
        return resolve({ text })
      }

      if (currentTextTarget === TextTarget.DICTIONARY) {
        return resolve(getDictionarySelection())
      }

      return resolve(getBody())
    })

    const { text, selection } = body

    // Check if selection was changed since last PlayClick
    const shouldResume =
      status === Status.PAUSED &&
      selection &&
      currentSelection === selection.start &&
      currentSelectionLength === selection.length

    // Update current selection to current value
    if (selection) {
      currentSelection = selection.start
      currentSelectionLength = selection.length
    }

    if (shouldResume) {
      tts.resume()
    } else if (status === Status.SPEAKING) {
      tts.pause()
    } else {
      tts.stop()

      const lang = store.language
      const textToSpeak = selection
        ? getTextToSpeak(TTSMode.READING, text, selection)
        : text
      const voiceURI = store.languages[lang]?.voiceURI
      const { languages } = store

      const options: SpeakerOptions = {
        lang,
        voiceURI,
        speed,
        selection,
        languages,
        enableLangDetector: store.language_detection
      }

      tts.speak(textToSpeak, options)
    }
  }

  const size = mini ? undefined : 'lg'

  return (
    <ButtonGroup size={size} className='w-100'>
      <Button variant='light' onClick={() => tts.prev()}>
        <FontAwesomeIcon icon={faStepBackward} />
      </Button>
      <Button
        disabled={status === Status.BOOTING}
        style={{ flexGrow: 3, flexBasis: '4rem' }}
        variant='primary'
        onClick={handlePlayClick}
      >
        {status === Status.LOADING ? (
          <Spinner
            as='span'
            animation='grow'
            size='sm'
            role='status'
            aria-hidden='true'
            className='align-middle'
            style={{
              marginTop: mini ? -4 : undefined
            }}
          />
        ) : (
          <FontAwesomeIcon
            icon={status === Status.SPEAKING ? faPause : faPlay}
          />
        )}
      </Button>
      <Button
        variant='light'
        style={{ flexGrow: 3, flexBasis: '4rem' }}
        onClick={() => {
          tts.stop()
        }}
      >
        <FontAwesomeIcon icon={faStop} />
      </Button>
      <Button variant='light' onClick={() => tts.next()}>
        <FontAwesomeIcon icon={faStepForward} />
      </Button>
    </ButtonGroup>
  )
}

export default CommandBar
