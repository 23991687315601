import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'
import TTSLangSwitcher from './TTSLangSwitcher'
import UILangSwitcher from './UILangSwitcher'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const LangSwitchers = () => {
  const { t } = useTranslation()
  return (
    <Card className='mt-3'>
      <Card.Body>
        <Card.Title>{t('language-heading')}</Card.Title>
        <Form>
          <Row>
            <Col md={6}>
              <UILangSwitcher />
            </Col>
            <Col md={6}>
              <TTSLangSwitcher label={`${t('speech')}:`} />
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  )
}

export default LangSwitchers
