import Spinner from 'react-bootstrap/Spinner'

const Loader = ({ content }: { content?: string }) => {
  return (
    <div className='d-flex flex-column min-vh-100 justify-content-center align-items-center'>
      <Spinner animation='border' role='status'>
        {!content && <span className='sr-only'>Loading...</span>}
      </Spinner>
      {!!content && <p className='mt-2'>{content}</p>}
    </div>
  )
}

export default Loader
