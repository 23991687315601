import { useCallback, useContext, useEffect, useState, useRef } from 'react'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import { useTranslation } from 'react-i18next'
import { StorageContext } from '../../context/Storage'
import storage, { Store } from '../../global/storage'
import { capitalize } from '@oribi/tts'
import { CompactToggle } from './Toggle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import tts from '../../global/tts'

enum SpeedRange {
  MIN = 30,
  MAX = 300
}

type Props = {
  compact?: boolean
}

const SpeedSlider = ({ compact }: Props) => {
  const { store } = useContext(StorageContext)
  const { t } = useTranslation()
  const languages = store.languages as Store['languages']

  const setSpeed = useCallback(
    (newValue: number) => {
      // JS and floating points don't match 🤦
      const percent = Math.round(newValue * 100)
      if (percent > SpeedRange.MAX || percent < SpeedRange.MIN) return

      const speed = percent / 100
      // const languages = store.languages

      if (languages[store.language] && !languages[store.language].speed)
        tts.setSpeed(speed)

      storage.set({ speed })
    },
    [languages, store.language]
  )

  const handleKeyDown = useCallback(
    ({ key }: KeyboardEvent) => {
      const { speed } = storage.get('speed')
      if (key === '+') setSpeed(speed + 0.1)
      if (key === '-') setSpeed(speed - 0.1)
    },
    [setSpeed]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  })

  const handleChange = (id: string, value: string) => {
    const slider = languages[id]

    if (slider) {
      slider.speed = parseFloat(value) / 100
      storage.set({ languages })

      if (id === store.language) {
        tts.setSpeed(slider.speed)
      }
    } else {
      setSpeed(parseFloat(value) / 100)
    }
  }

  // const composeLabel = (speed: number) =>
  //   `${Math.floor(speed)}% (~${Math.floor(speed * 1.8)} ${t('wpm')})`

  const speedLanguages = Object.keys(languages)

  const availableLanguages = Object.keys(languages)
    .filter(lang => !Object.keys(languages[lang]).includes('speed'))
    .map(lang => ({
      key: lang,
      text: capitalize(t(lang)),
      value: lang
    }))

  const showAddLanguageSpeedButton =
    Object.values(languages).length > 1 && availableLanguages.length >= 1

  const addLanguage = (lang: string) => {
    languages[lang].speed = store.speed
    storage.set({ languages })
  }

  const onChange: React.ChangeEventHandler<HTMLSelectElement> = ({
    target: { value: lang }
  }) => {
    addLanguage(lang)
  }

  const showMainSliderInSettings =
    compact || (!compact && availableLanguages.length !== 0)

  const chosenLanguageCustomSpeed = languages[store.language].speed

  const noAddedLanguageSpecificSliders =
    speedLanguages.length === availableLanguages.length

  return (
    <Card className='mt-3'>
      <Card.Body className='pb-3'>
        {!compact && (
          <>
            <Card.Title>{t('speed')}</Card.Title>
            <Card.Text>{t('speed_desc')}</Card.Text>
          </>
        )}
        <Form.Group className='mb-0'>
          {showMainSliderInSettings && (
            <>
              <Form.Label>
                {compact ? (
                  `${t('speed')}: `
                ) : (
                  <strong>
                    {t('global-speed-label')}
                    {' - '}
                  </strong>
                )}
                <SpeedValue
                  showPercentage={true}
                  speed={
                    compact && chosenLanguageCustomSpeed
                      ? Math.round(chosenLanguageCustomSpeed * 100)
                      : Math.round(store.speed * 100)
                  }
                  label={t('wpm')}
                />
              </Form.Label>
              <Form.Control
                id={
                  languages[store.language].speed && compact
                    ? store.language
                    : 'main'
                }
                custom
                type='range'
                min={SpeedRange.MIN}
                max={SpeedRange.MAX}
                step={10}
                value={
                  compact && chosenLanguageCustomSpeed
                    ? Math.round(chosenLanguageCustomSpeed * 100)
                    : Math.round(store.speed * 100)
                }
                onChange={({ target: { id, value } }) => {
                  handleChange(id, value)
                }}
              />
              {!compact &&
              availableLanguages.length !== speedLanguages.length ? (
                availableLanguages.map(item => (
                  <>
                    <Badge variant='light mr-3'>{item.text}</Badge>
                  </>
                ))
              ) : (
                <></>
              )}
            </>
          )}
        </Form.Group>
        {!compact && <CompactToggle setting='speed' />}
      </Card.Body>
      {!compact && (
        <ListGroup
          variant='flush'
          as='ul'
          className={noAddedLanguageSpecificSliders ? 'border-top-0' : ''}
        >
          {speedLanguages.map(lang => (
            <LanguageSpecificSliders
              key={lang}
              compact={compact}
              lang={lang}
              onChange={handleChange}
            />
          ))}
        </ListGroup>
      )}
      {!compact && showAddLanguageSpeedButton && (
        <>
          <Card.Footer>
            <Form>
              <Form.Control as='select' custom value='0' onChange={onChange}>
                <option disabled value='0'>
                  {t('add_speed')}
                </option>
                {availableLanguages.map(({ key, value, text }) => (
                  <option key={key} value={value}>
                    {text}
                  </option>
                ))}
              </Form.Control>
            </Form>
          </Card.Footer>
        </>
      )}
    </Card>
  )
}

const SpeedValue = ({
  showPercentage,
  speed,
  label
}: {
  showPercentage: boolean
  speed: number
  label: string
}) => (
  <>
    {showPercentage && <span>{Math.floor(speed)}%</span>}
    <span className='d-none d-sm-inline'>
      {' '}
      (~{Math.floor(speed * 1.8)} {label})
    </span>
  </>
)

const LanguageSpecificSliders = ({
  compact,
  lang,
  onChange
}: {
  compact?: boolean
  lang: string
  onChange: (id: string, value: string) => void
}) => {
  const { store } = useContext(StorageContext)
  const languages = store.languages as Store['languages']
  const { t } = useTranslation()
  const title = capitalize(t(lang))
  const { speed } = languages[lang]
  const [expanded, setExpanded] = useState(false)
  const toggle = useRef<HTMLButtonElement>(null)
  const toggleExpanded = () => setExpanded(!expanded)

  const removeSpeed = (lang: string) => {
    delete languages[lang].speed
    storage.set({ languages })
  }

  return (
    <>
      {speed && (
        <ListGroup.Item key={lang} as='li' className='px-3'>
          <div className='d-flex justify-content-between align-items-center flex-nowrap'>
            <Button
              variant='link'
              className={'text-left'}
              onClick={toggleExpanded}
              ref={toggle}
            >
              <strong>{title}</strong> - {Math.round(speed * 100) + '%'}
            </Button>

            <Button
              size='sm'
              variant='link'
              className='text-reset'
              onClick={() => removeSpeed(lang)}
            >
              <FontAwesomeIcon icon={faTrash} fixedWidth />
              <span aria-hidden='true' className='sr-only'>
                {t('remove_voice')}
              </span>
            </Button>
          </div>
          {expanded && (
            <Form.Group controlId={lang} className='mb-0'>
              <Form.Label>{compact ? `${t('speed')}: ` : ''}</Form.Label>
              <Form.Control
                className='py-3'
                custom
                type='range'
                min={SpeedRange.MIN}
                max={SpeedRange.MAX}
                step={10}
                value={Math.round(speed * 100)}
                onChange={({ target: { id, value } }) => {
                  onChange(id, value)
                }}
              />
              <SpeedValue
                showPercentage={false}
                speed={Math.round(speed * 100)}
                label={t('wpm')}
              />
            </Form.Group>
          )}
        </ListGroup.Item>
      )}
    </>
  )
}

export default SpeedSlider
