import { useContext } from 'react'
import { LogOutBtn, UserContext } from '../../context/User'
import { useTranslation } from 'react-i18next'
import { version } from '../../../package.json'
import Card from 'react-bootstrap/Card'
import LangSwitchers from '../../components/LangSwitchers'
import { enableTalkingKeyboard } from '../../global/env'
import SpeedSlider from './SpeedSlider'
import TalkingKeyboardSwitch from './TalkingKeyboardSwitch'
import LangDetectorSwitch from './LangDetectorSwitch'
import HighlighterSwitch from './HighlighterSwitch'
import Voices from './Voices'
import StartOnboardingBtn from '../Onboarding/StartOnboardingBtn'

const Settings = () => {
  const { t } = useTranslation()
  const {
    user: { email, id }
  } = useContext(UserContext)

  return (
    <>
      <Voices />
      <SpeedSlider />
      {enableTalkingKeyboard && <TalkingKeyboardSwitch />}
      <LangDetectorSwitch compactToggle />
      <HighlighterSwitch compactToggle />
      {id !== 'NO_SYNC' && (
        <Card className='mt-3'>
          <Card.Body>
            <Card.Title>{t('settings-account-heading')}</Card.Title>
            <Card.Text>
              {t('settings-account-content')} {email}
            </Card.Text>
            <LogOutBtn />
          </Card.Body>
        </Card>
      )}
      <LangSwitchers />
      <Card className='mt-3'>
        <Card.Body>
          <Card.Title>{t('settings-about-heading')} Oribi Speak</Card.Title>
          <Card.Text>{t('settings-about-content')}</Card.Text>
          <Card.Text>Version {version}.</Card.Text>
          <Card.Text>{navigator.userAgent}</Card.Text>
          <StartOnboardingBtn />
        </Card.Body>
      </Card>
    </>
  )
}

export default Settings
