import './bootstrap.scss'
import './style.css'
import './i18n'
import React, { FC } from 'react'
import ReactDOM from 'react-dom'
import Host from './context/Host'
import Storage from './context/Storage'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ErrorHandler from './context/Error'
import User from './context/User'
import TTS from './context/TTS'
import Dialog from './Dialog'
import Sidebar from './Sidebar'
import Onboarding from './context/Onboarding'

// Will become React context when Habitat is finished
const License: FC = ({ children }) => <>{children}</>

ReactDOM.render(
  <React.StrictMode>
    <Host>
      <Storage>
        <Router>
          <Switch>
            <Route path={['/dialog/:id', '/dialog']}>
              <Dialog />
            </Route>
            <Route path='/'>
              <ErrorHandler>
                <Onboarding>
                  <User>
                    <License>
                      <TTS>
                        <Sidebar />
                      </TTS>
                    </License>
                  </User>
                </Onboarding>
              </ErrorHandler>
            </Route>
          </Switch>
        </Router>
      </Storage>
    </Host>
  </React.StrictMode>,
  document.getElementById('root')
)
