import { FC, useContext } from 'react'
import storage, { Store } from '../../global/storage'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import { StorageContext } from '../../context/Storage'
import { useTranslation } from 'react-i18next'

type Props = {
  title: string
  setting: keyof Store
  compact?: boolean
  compactToggle?: boolean
  description?: string
}

export const CompactToggle = ({ setting }: { setting: string }) => {
  const { store } = useContext(StorageContext)
  const { t } = useTranslation()
  const itemKey = (setting + '_compact') as keyof Store
  const checked = !!store[itemKey]

  return (
    <Form className='mt-3'>
      <Form.Check
        id={setting + '_compact_switch'}
        checked={checked}
        onChange={({ target: { checked } }) => {
          storage.setLocal(itemKey, !!checked)
        }}
        label={t('show_in_main')}
        custom
      />
    </Form>
  )
}

const Toggle: FC<Props> = props => {
  const { compact, setting, title, description, compactToggle } = props
  const { store } = useContext(StorageContext)

  return (
    <Card className='mt-3'>
      <Card.Body>
        <Card.Text className={compact ? '' : 'mb-0 h5'} as='div'>
          <Form.Check
            type='switch'
            id={setting + '_switch'}
            checked={!!store[setting as keyof Store]}
            label={title}
            onChange={({ target: { checked } }) => {
              storage.set({ [setting]: !!checked })
            }}
          />
        </Card.Text>
        {description && !compact && (
          <Card.Text className='mt-3'>{description}</Card.Text>
        )}
        {!compact && props.children}
        {!compact && !!compactToggle && <CompactToggle setting={setting} />}
      </Card.Body>
    </Card>
  )
}

export default Toggle
