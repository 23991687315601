import { useContext, useEffect } from 'react'
import Main from './Main'
import Settings from './Settings'
import Layout, { View } from '../components/Layout'
import Dictionary from './Dictionary'
import TalkingKeyboard from '../components/TalkingKeyboard'
import { HostContext } from '../context/Host'
import { faBook, faCog, faFileWord } from '@fortawesome/free-solid-svg-icons'
import { enableTalkingKeyboard } from '../global/env'
import { useTranslation } from 'react-i18next'
import { ErrorContext } from '../context/Error'
import WordApi from './WordApi'
import Toast from 'react-bootstrap/Toast'
import Highlighter from '../components/Highlighter'
import { setCurrentTextTarget, TextTarget } from '../global/tts'

const Sidebar = () => {
  const { host } = useContext(HostContext)
  const { lastError, setLastError } = useContext(ErrorContext)

  const { t } = useTranslation()

  const views: View[] = [
    {
      menuItem: 'speak-menu-item',
      render: () => <Main />
    },
    {
      menuItem: 'dictionary-menu-item',
      icon: faBook,
      render: visible => <Dictionary visible={visible} />,
      miniPlayer: true,
      onOpen: () => {
        const event = new Event('dictionaryOpen')
        document.dispatchEvent(event)
      }
    },
    {
      menuItem: 'settings-menu-item',
      render: () => <Settings />,
      icon: faCog,
      miniPlayer: true
    }
  ]

  if (
    process.env.REACT_APP_CONTEXT === 'localhost' &&
    host === Office.HostType.Word
  ) {
    views.splice(1, 0, {
      menuItem: 'word-api-playground',
      icon: faFileWord,
      render: () => <WordApi />,
      miniPlayer: true
    })
  }

  useEffect(() => {
    if (host === null) {
      setLastError(new Error(t('error_no_office')))
    }
  }, [host, setLastError, t])

  useEffect(() => {
    document.addEventListener('selectionchange', () => {
      const selection = document.getSelection()
      if (selection) {
        const parentElement = selection.focusNode?.parentElement
        const sidebar = document.getElementById('sidebar-inner')

        if (parentElement && sidebar && sidebar.contains(parentElement)) {
          setCurrentTextTarget(TextTarget.SELF)
        }
      }
    })

    if (host === 'Standalone') {
      const textarea = document.getElementById('standalone-textarea')
      textarea?.addEventListener('click', () => {
        setCurrentTextTarget(TextTarget.EDITOR)
      })
    }

    if (host === Office.HostType.Word) {
      Office.context.document.addHandlerAsync(
        Office.EventType.DocumentSelectionChanged,
        () => {
          setCurrentTextTarget(TextTarget.EDITOR)
        }
      )
    }
  }, [host])

  return (
    <>
      <Layout views={views} />
      <Highlighter />
      {!!host && enableTalkingKeyboard && (
        <TalkingKeyboard onError={error => setLastError(error)} />
      )}
      {!!lastError && (
        <div className='m-3 fixed-bottom'>
          <Toast
            show
            onClose={() => setLastError()}
            className='mx-auto bg-white'
          >
            <Toast.Header>
              <strong className='mr-auto'>{t('toast_title_error')}</strong>
            </Toast.Header>
            <Toast.Body>{lastError.message}</Toast.Body>
          </Toast>
        </div>
      )}
    </>
  )
}

export default Sidebar
