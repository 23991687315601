import { useEffect } from 'react'
import { createContext, useState, FC } from 'react'
// import { User, UserContext } from './User'
import storage, { defaultStore, Store } from '../global/storage'
// import { UserProperties } from '@oribi/auth'

type StorageContextType = {
  store: Store
  setStore: (newStore: Store) => void
}

export const defaultStoreContext: StorageContextType = {
  store: defaultStore,
  setStore: () => {}
}

export const StorageContext = createContext(defaultStoreContext)

export const StorageProvider: FC = ({ children }) => {
  const [store, setStore] = useState(defaultStoreContext.store)

  useEffect(() => {
    storage.addOnChangedListener(({ property, newValue }) => {
      const all = storage.get(null) as Store
      setStore({
        ...all,
        [property]: newValue
      })
    })
  }, [])

  const value = { ...defaultStoreContext, store, setStore }

  return (
    <StorageContext.Provider value={value}>{children}</StorageContext.Provider>
  )
}

export default StorageProvider
