import CommandBar from '../../components/CommandBar'
import Form from 'react-bootstrap/Form'
import SpeedSlider from '../Settings/SpeedSlider'
import TTSLangSwitcher from '../../components/LangSwitchers/TTSLangSwitcher'
import { enableTalkingKeyboard } from '../../global/env'
import TalkingKeyboardSwitch from '../Settings/TalkingKeyboardSwitch'
import LangDetectorSwitch from '../Settings/LangDetectorSwitch'
import HighlighterSwitch from '../Settings/HighlighterSwitch'
import { useContext } from 'react'
import { StorageContext } from '../../context/Storage'

const Main = () => {
  const { store } = useContext(StorageContext)

  return (
    <Form>
      <TTSLangSwitcher />
      <CommandBar />
      <div className='card-stack mt-3'>
        {!!store.speed_compact && <SpeedSlider compact />}
        {enableTalkingKeyboard && <TalkingKeyboardSwitch compact />}
        {!!store.language_detection_compact && <LangDetectorSwitch compact />}
        {!!store.highlighting_compact && <HighlighterSwitch compact />}
      </div>
    </Form>
  )
}

export default Main
