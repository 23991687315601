import { createContext, FC, useState } from 'react'
import storage from '../global/storage'
import OnboardingView from '../Sidebar/Onboarding'

type OnboardingContextType = {
  onboarded: boolean
  setOnboarded: (value: boolean) => void
}

const defaultContext: OnboardingContextType = {
  onboarded: false,
  setOnboarded: () => {}
}

export const OnboardingContext = createContext(defaultContext)

export const OnboardingProvider: FC = ({ children }) => {
  const [onboarded, setOnboardedState] = useState<boolean>(
    storage.getLocal('onboarded') || false
  )

  const setOnboarded = (value: boolean) => {
    storage.setLocal('onboarded', value)
    setOnboardedState(value)
  }

  return (
    <OnboardingContext.Provider value={{ onboarded, setOnboarded }}>
      {children}
    </OnboardingContext.Provider>
  )
}

const Onboarding: FC = ({ children }) => (
  <OnboardingProvider>
    <OnboardingContext.Consumer>
      {({ onboarded, setOnboarded }) =>
        onboarded ? (
          children
        ) : (
          <OnboardingView
            onSuccess={() => {
              setOnboarded(true)
            }}
          />
        )
      }
    </OnboardingContext.Consumer>
  </OnboardingProvider>
)

export default Onboarding
