import { manifests, common } from '../_manifest.json'

export type AppManifest = {
  name: string
  defaultLocale: string
  description: string
  supportUrl: string
  suffix: string
  guid: string
  host: string
  azureClientId: string
  googleClicentId: string
}

// Assign app specific manifest data to common object
export const manifest = common as AppManifest
Object.assign(
  manifest,
  manifests.find(({ host }) => host === window.location.host)
)

export const dev = Boolean(manifest.suffix)
