import tts from '../global/tts'
import { TTSEventHandler } from '@oribi/tts'
import { setDocumentSelection } from '@oribi/office-js'
import { StorageContext } from '../context/Storage'
import { useContext, useCallback, useEffect } from 'react'
import { ErrorContext } from '../context/Error'
import { HostContext } from '../context/Host'
import { setEditorSelection } from '../StandaloneEditor'

const Highlighter = () => {
  const { store } = useContext(StorageContext)
  const { host } = useContext(HostContext)
  const { setLastError } = useContext(ErrorContext)

  const highlighting = store.highlighting as boolean

  const highlighter: TTSEventHandler = useCallback(
    event => {
      if (!document.hasFocus()) {
        return
      }
      const { charIndex: start, charLength: length } = event

      if (start !== undefined && length !== undefined) {
        if (host === 'Standalone') {
          setEditorSelection({ start, length })
        } else {
          setDocumentSelection({ start, length }).catch(setLastError)
        }
      }
    },
    [setLastError, host]
  )

  useEffect(() => {
    if (highlighting) {
      tts.addEventHandler(highlighter)
    } else {
      tts.removeEventHandler(highlighter)
    }
  }, [highlighting, highlighter])

  return <></>
}

export default Highlighter
