import { createContext, FC, useState } from 'react'

export class FatalError extends Error {}

type ErrorContextType = {
  lastError?: Error | FatalError
  setLastError: (error?: Error | FatalError) => void
}

const defaultContext: ErrorContextType = {
  setLastError: () => {}
}

export const ErrorContext = createContext(defaultContext)

export const ErrorProvider: FC = ({ children }) => {
  const [lastError, setLastError] = useState<Error | FatalError | undefined>()

  return (
    <ErrorContext.Provider value={{ setLastError, lastError }}>
      {children}
    </ErrorContext.Provider>
  )
}

const ErrorHandler: FC = ({ children }) => (
  <ErrorProvider>
    <ErrorContext.Consumer>
      {({ lastError }) => {
        if (!!lastError && FatalError.prototype.isPrototypeOf(lastError)) {
          return <p>{lastError.message}</p>
        }

        return children
      }}
    </ErrorContext.Consumer>
  </ErrorProvider>
)

export default ErrorHandler
