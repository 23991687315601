import { useTranslation } from 'react-i18next'
import Toggle from './Toggle'

type Props = {
  compact?: boolean
  compactToggle?: boolean
}

const LangDetectorSwitch = ({ compact, compactToggle }: Props) => {
  const { t } = useTranslation()

  return (
    <Toggle
      setting='language_detection'
      title={t('lang_detection_header')}
      description={t('lang_detection_desc')}
      compact={compact}
      compactToggle={compactToggle}
    />
  )
}

export default LangDetectorSwitch
