import { DocumentBody, Selection } from '@oribi/office-js'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

const getEditor = () => {
  return document.getElementById('standalone-textarea') as HTMLTextAreaElement
}

export const getEditorBody = async () => {
  const textarea = getEditor()

  const body: DocumentBody = {
    text: ''
  }

  const { value, selectionStart: start, selectionEnd: end } = textarea
  const length = end - start
  body.text = value
  body.selection = {
    start,
    length
  }

  return body
}

export const setEditorSelection = ({ start, length }: Selection) => {
  const textarea = getEditor()

  textarea.focus()
  textarea.setSelectionRange(start, start + length)
}

const Editor = ({ sidebar }: { sidebar: ReactNode }) => {
  const { t } = useTranslation()

  const welcomeMessage = t('standalone_welcome_msg')
  const storedText = localStorage.getItem('standaloneEditorValue')
  const defaultValue = storedText ? JSON.parse(storedText) : welcomeMessage

  return (
    <div
      id='editor-wrapper'
      className='d-flex flex-row overflow-hidden'
      style={{ width: '100vw', height: '100vh' }}
    >
      <div className='flex-grow-1 bg-white border-right p-4'>
        {' '}
        <textarea
          onInput={({ currentTarget: { value } }) => {
            if (value) {
              localStorage.setItem(
                'standaloneEditorValue',
                JSON.stringify(value)
              )
            } else {
              localStorage.removeItem('standaloneEditorValue')
            }
          }}
          defaultValue={defaultValue}
          autoFocus
          className='border-0 w-100 h-100'
          id='standalone-textarea'
          style={{ resize: 'none', outline: 'none', fontSize: '2rem' }}
        />
      </div>
      <div
        className='flex-grow-0'
        style={{ width: 320, overflowY: 'auto' }}
        id='sidebar'
      >
        {sidebar}
      </div>
    </div>
  )
}

export default Editor
