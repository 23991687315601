import TTSController from '@oribi/tts'

export default new TTSController({
  localService: window.speechSynthesis,
  mediaSession: navigator.mediaSession
})

export enum TextTarget {
  SELF,
  EDITOR,
  DICTIONARY
}
export let currentTextTarget = TextTarget.EDITOR
export const setCurrentTextTarget = (target: TextTarget) => {
  currentTextTarget = target
}
