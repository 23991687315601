import { faDesktop } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'
import storage from '../../global/storage'
import InputGroup from 'react-bootstrap/InputGroup'

const UILangSwitcher = () => {
  const { i18n, t } = useTranslation()
  return (
    <Form.Group controlId='ui-lang-select'>
      <Form.Label>{t('ui')}:</Form.Label>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text id='ui-lang-icon'>
            <FontAwesomeIcon icon={faDesktop} />{' '}
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          aria-label={t('ui')}
          aria-describedby='ui-lang-icon'
          as='select'
          custom
          value={i18n.language}
          onChange={({ target: { value } }) => {
            storage.setLocal('UI-language', value)
            i18n.changeLanguage(value)
          }}
        >
          <option value='sv'>Svenska</option>
          <option value='en'>English</option>
        </Form.Control>
      </InputGroup>
    </Form.Group>
  )
}

export default UILangSwitcher
