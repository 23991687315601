const MinimalIcon = () => (
  <svg
    viewBox='0 0 512 512'
    aria-hidden='true'
    focusable='false'
    data-icon='oribi-speak-icon'
    className='svg-inline--fa browser-action'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g>
      <path
        d='M256,0C114.62,0,0,114.62,0,256c0,50.3,14.72,97.08,39.78,136.65L0,512l119.35-39.78C158.92,497.28,205.7,512,256,512
		c141.38,0,256-114.62,256-256S397.38,0,256,0z M256,448c-106.04,0-192-85.96-192-192S149.96,64,256,64s192,85.96,192,192
		S362.04,448,256,448z'
        fill='currentColor'
      />
      <circle cx='256' cy='256' r='128' fill='currentColor' />
    </g>
  </svg>
)

export default MinimalIcon
