import { useTranslation } from 'react-i18next'
import Toggle from './Toggle'

type Props = {
  compact?: boolean
  compactToggle?: boolean
}

const HighlighterSwitch = ({ compact, compactToggle }: Props) => {
  const { t } = useTranslation()

  return (
    <Toggle
      setting='highlighting'
      title={t('highlighter-label')}
      description={t('highlighter-content')}
      compact={compact}
      compactToggle={compactToggle}
    />
  )
}

export default HighlighterSwitch
