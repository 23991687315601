import { DocumentBody } from '@oribi/office-js'
import { useEffect } from 'react'
import { createContext, useState, FC } from 'react'
import Loader from '../components/Loader'
import StandaloneEditor, { getEditorBody } from '../StandaloneEditor'
import { getDocumentBody } from '@oribi/office-js'

/**
 * If host or platform are undefined, they still haven't been determined. If
 * null, script was probably loaded outside of Office client.
 */
type HostContextType = {
  host?: Office.HostType | 'Standalone' | null
  platform?: Office.PlatformType | 'Browser' | null
  errors: string[]
  getBody: () => Promise<DocumentBody>
}

export const defaultHostContext: HostContextType = {
  errors: [],
  getBody: () =>
    Promise.resolve({
      text: ''
    })
}

export const HostContext = createContext(defaultHostContext)

type RuntimeChecker = (
  host: Office.HostType,
  platform: Office.PlatformType
) => string[]

const getRuntimeErrors: RuntimeChecker = (host, platform) => {
  const errors: string[] = []

  // Body.getRange requires WordApi 1.3...
  // https://github.com/OfficeDev/office-js-docs/blob/master/reference/requirement-sets/word-api-requirement-sets.md
  const isWord = host === Office.HostType.Word
  const isWordUpdated = Office.context.requirements.isSetSupported(
    'WordApi',
    '1.3'
  )
  if (isWord && !isWordUpdated) {
    let minVersion

    switch (platform) {
      case Office.PlatformType.PC:
        minVersion = '1612 (Build 7668.1000)'
        break
      case Office.PlatformType.Mac:
        minVersion = '15.32'
        break
      case Office.PlatformType.iOS:
        minVersion = '2.22'
        break
      default:
        minVersion = '2016'
        break
    }

    const message = `'error_word_api' ${minVersion}` // this.i18n._('error_word_api', [app, minVersion])
    errors.push(message)
  }

  // const isOneNote = host === Office.HostType.OneNote
  // const isOneNoteUpdated = Office.context.requirements.isSetSupported(
  //   'OneNoteApi',
  //   '1.1'
  // )
  // if (isOneNote && !isOneNoteUpdated) {
  //   const message = 'error_onenote_api' // this.i18n._('error_onenote_api', [app])
  //   errors.push(message)
  // }

  const isMacOS = platform === Office.PlatformType.Mac
  const macOSInfo = navigator.userAgent.match(/OS\sX\s(\d\d_\d\d)/)
  if (isMacOS && !!macOSInfo) {
    // macOSInfo is either something like ["OS X 10_15", "10_15"] or null
    const macOSVersion = macOSInfo[1].replace('_', '.') // "10.15"
    const versionNumber = parseFloat(macOSVersion) // 10.15

    // Require macOS High Sierra or later
    if (versionNumber < 10.13) {
      const message = 'error_macos' // this.i18n._('error_macos', [app])
      errors.push(message)
    }
  }

  const safariInfo = navigator.appVersion.match(/Version\/(\d+\.?\S*)\sSafari/)
  if (!!safariInfo) {
    const safariVersion = safariInfo[1]
    const versionNumber = parseFloat(safariVersion)
    if (versionNumber < 11.1) {
      const message = 'error_safari' // this.i18n._('error_safari', [app])
      errors.push(message)
    }
  }

  return errors
}

export const HostProvider: FC = ({ children }) => {
  const [value, setValue] = useState(defaultHostContext)

  useEffect(() => {
    // Run Office.initialize once just in case...
    // https://github.com/OfficeDev/office-js/issues/246#issuecomment-437260860
    Office.initialize = () => {}

    let { host, platform, errors, getBody } = defaultHostContext

    Office.onReady().then(office => {
      host = office.host
      platform = office.platform

      let isDialog: boolean
      try {
        // isDialog becomes available at runtime but doesn't exist on Office.Context type
        // @ts-expect-error
        isDialog = Office.context.isDialog
      } catch (_error) {
        // Assume that ui is in dialog and skip runtime checks
        isDialog = true
      }

      // Only check runtime requirement sets if Office has loaded in taskpane
      if (office.host !== null && !isDialog) {
        errors = getRuntimeErrors(host, platform)
      }

      if (host !== null) {
        document.documentElement.dataset.host = host.toString()
      }
      if (platform !== null) {
        document.documentElement.dataset.platform = platform.toString()
      }

      if (host === null && platform === null) {
        host = 'Standalone'
        platform = 'Browser'
      }

      getBody = getBody =
        host === 'Standalone' ? getEditorBody : getDocumentBody

      setValue({ host, platform, errors, getBody })
    })
  }, [])

  return <HostContext.Provider value={value}>{children}</HostContext.Provider>
}

const Host: FC = ({ children }) => (
  <HostProvider>
    <HostContext.Consumer>
      {({ host, errors }) => {
        if (host === undefined) return <Loader />
        if (errors.length) {
          return (
            <>
              <h1>Errors</h1>
              <ul>
                {errors.map((error, i) => (
                  <li key={i}>{error}</li>
                ))}
              </ul>
            </>
          )
        }

        return host === 'Standalone' ? (
          <StandaloneEditor sidebar={children} />
        ) : (
          children
        )
      }}
    </HostContext.Consumer>
  </HostProvider>
)

export default Host
