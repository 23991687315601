import { isUser, UserContext } from '../context/User'
import { dev } from '../global/app'
import { Trans, useTranslation } from 'react-i18next'
import SimulateSignIn from './Simulator'
import { useContext, useState } from 'react'
import layout from '../components/Layout.module.css'
import { HostContext } from '../context/Host'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { User } from '../context/User'
import storage from '../global/storage'
import { displayDialogAsync } from '@oribi/office-js'
import { requestGraphUser } from '@oribi/auth'
import StartOnboardingBtn from '../Sidebar/Onboarding/StartOnboardingBtn'

type InlineStyles = {
  [key: string]: React.CSSProperties
}
const inlineStyles: InlineStyles = {
  wrapper: {
    background: 'url(/assets/backgrounds/bg-blobs.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundAttachment: 'fixed'
  },
  main: {
    textAlign: 'center',
    maxWidth: 480,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    width: 96
  },
  h1: { fontSize: '2.5rem', marginTop: '.5rem' }
}

export const getUserFromAccessToken = (
  accessToken: string
): Promise<User | undefined> =>
  new Promise(resolve => {
    requestGraphUser(accessToken)
      .then(({ success, user }) => {
        if (!success || !user) throw new Error('unsuccessfull graph request')

        const { id, email } = user
        if (!isUser({ id, email })) throw new Error('invalid graph user')

        resolve({ id, email })
      })
      .catch(() => {
        resolve(undefined)
      })
  })

export const logInPopUp = (): Promise<{ user?: User }> => {
  return new Promise(resolve => {
    displayDialogAsync(window.location.origin + '/dialog/login', {
      onError: _ => {
        resolve({ user: undefined })
      },
      onMessage: (message, dialog) => {
        if (typeof message !== 'object') return

        if ('accessToken' in message) {
          const { accessToken } = message as { accessToken: string }
          storage.setLocal('msalAccessToken', accessToken)
          dialog.close()

          getUserFromAccessToken(accessToken).then(user => {
            resolve({ user })
          })
        } else {
          resolve({ user: undefined })
        }
      },
      onClose: () => {
        resolve({ user: undefined })
      }
    })
  })
}

export const LogInBtn = () => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { host } = useContext(HostContext)
  const { setUser } = useContext(UserContext)

  const isDisabled = host === null
  const text = loading
    ? t('signing_in')
    : t('log_in_with_provider', { provider: 'Microsoft' })

  return (
    <Button
      size='lg'
      disabled={isDisabled || loading}
      onClick={() => {
        setLoading(true)
        logInPopUp().then(({ user }) => {
          setLoading(false)
          if (user) {
            setUser(user)
          }
        })
      }}
    >
      <FontAwesomeIcon fixedWidth icon={faMicrosoft} className='mr-1' />
      {text}
    </Button>
  )
}

const SignIn = () => {
  const { t } = useTranslation()
  const { setUser } = useContext(UserContext)

  return (
    <div className={layout.wrapper} style={inlineStyles.wrapper}>
      {/* <header className={`${layout.header} pt-5`}></header> */}
      <main role='main' className={layout.main} style={inlineStyles.main}>
        <img
          style={inlineStyles.img}
          src='/icons/icon_256x256.png'
          alt='Oribi Speak icon'
        />
        <h1>Oribi Speak</h1>
        <ButtonGroup className='w-100 mt-4' vertical>
          <LogInBtn />
          <StartOnboardingBtn />
          {dev && (
            <SimulateSignIn
              onSuccess={user => {
                storage.setLocal('simulatedUser', user)
                setUser(user)
              }}
            />
          )}
        </ButtonGroup>
        {/* <p>{t('log_in_new_window')}</p> */}
        <p className='mt-3'>
          {t('log_in_no_provider_account', { provider: 'Microsoft' })} <br />
          <a
            href='https://signup.live.com/signup'
            target='_blank'
            rel='noopener noreferrer'
            style={{ textDecoration: 'underline ' }}
          >
            {t('log_in_get_provider_account')}
          </a>
        </p>
      </main>
      <footer className={layout.footer}>
        <Trans i18nKey='footer-text'>
          <a
            href='https://oribi.se'
            className='link'
            target='_blank'
            rel='noreferrer'
          >
            Oribi
          </a>
        </Trans>
      </footer>
    </div>
  )
}

export default SignIn
