import { useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import storage, { Store } from '../../global/storage'
import tts from '../../global/tts'
import { StorageContext } from '../../context/Storage'
import {
  capitalize,
  composeGreeting,
  findVoiceInLang,
  getVoiceFromURI,
  UserLanguages,
  Voice
} from '@oribi/tts'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faTrash, faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import FlagOrNull from '../../components/FlagOrNull'
import { ErrorContext } from '../../context/Error'
import { dev } from '../../global/app'

const removeLanguage = (lang: string) => {
  const { languages, language } = storage.get('languages', 'language')
  if (!!languages[lang]) {
    delete languages[lang]
    storage.set({ languages })
  }

  // Did user delete active language?
  const addedLanguages = Object.keys(languages)
  if (!addedLanguages.includes(language)) {
    storage.set({ language: addedLanguages[0] })
  }
}

const addLanguage = (lang: string) => {
  const { languages } = storage.get('languages')
  const voice = findVoiceInLang(lang, tts.voices)

  if (voice) {
    const { voiceURI } = voice
    languages[lang] = { voiceURI }
    storage.set({ languages })
  }
}

const addLanguages = (langs: string[]) => {
  const { languages } = storage.get('languages') as { languages: UserLanguages }
  for (const lang of langs) {
    const voice = findVoiceInLang(lang, tts.voices)
    if (!voice || lang in languages) continue
    const { voiceURI } = voice
    languages[lang] = { voiceURI }
  }
  storage.set({ languages })
}

const changeVoice = (lang: string, voiceURI: Voice['voiceURI']) => {
  const { languages } = storage.get('languages')
  lang = lang.split('-')[0]
  if (languages[lang]) {
    languages[lang].voiceURI = voiceURI
    storage.set({ languages })
  }
}

// Fired when user clicks the remove button
const handleRemoveClick = (target: HTMLButtonElement, lang: string) => {
  const { parentElement } = target

  let focusTarget: HTMLButtonElement | undefined
  const prev = parentElement?.previousElementSibling
  const next = parentElement?.nextElementSibling

  if (prev) {
    focusTarget = prev.firstElementChild as HTMLButtonElement
  } else {
    focusTarget = next?.firstElementChild as HTMLButtonElement
  }

  if (focusTarget) focusTarget.focus()

  removeLanguage(lang)
}

const Voices = () => {
  const { t } = useTranslation()
  const store = useContext(StorageContext).store as Store

  const { languages } = store
  const addedLangs = Object.keys(languages)
  const removable = addedLangs.length > 1

  const remainingLangs = tts.languages.filter(
    lang => !addedLangs.includes(lang)
  )

  const langOptions = remainingLangs.map(lang => ({
    text: capitalize(t(lang)),
    value: lang
  }))

  return (
    <Card>
      <Card.Body>
        <Card.Title>{t('voices-heading')}</Card.Title>
        <Card.Text>{t('voices_description')}</Card.Text>
      </Card.Body>
      <ListGroup id='voice-handler' variant='flush' as='ul'>
        {addedLangs.map(lang => (
          <AddedLanguage key={lang} lang={lang} removable={removable} />
        ))}
      </ListGroup>
      <Card.Footer>
        <Form>
          <Form.Control
            as='select'
            custom
            value='0'
            onChange={({ target: { value } }) => {
              if (value === 'all') {
                addLanguages(remainingLangs)
              } else {
                addLanguage(value)
              }
            }}
            disabled={!remainingLangs.length}
          >
            <option disabled value='0'>
              {t('add_voice')}
            </option>
            {langOptions.map(({ value, text }) => (
              <option key={value} value={value}>
                {text}
              </option>
            ))}
            {dev && !!remainingLangs.length && (
              <optgroup label='Syns endast internt'>
                <option value='all'>
                  Lägg till alla {remainingLangs.length} språk
                </option>
              </optgroup>
            )}
          </Form.Control>
        </Form>
      </Card.Footer>
    </Card>
  )
}

const AddedLanguage = (props: { lang: string; removable: boolean }) => {
  const { lang, removable } = props

  const { t } = useTranslation()
  const store = useContext(StorageContext).store as Store
  const toggle = useRef<HTMLButtonElement>(null)
  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = () => setExpanded(!expanded)

  const title = capitalize(t(lang))
  const { voiceURI } = store.languages[lang]
  const voice = getVoiceFromURI(voiceURI, tts.voices)

  const voiceList = tts.voices.filter(
    voice => voice.lang.split('-')[0] === lang
  )

  return (
    <ListGroup.Item as='li' className='px-3'>
      <div className='d-flex justify-content-between align-items-center flex-nowrap'>
        <Button
          variant='link'
          className={'text-left ' + (voice ? 'text-reset' : 'text-danger')}
          onClick={toggleExpanded}
          ref={toggle}
        >
          <strong>{title}</strong> -{' '}
          {voice ? voice.name : t('voice-unavailable', { voiceURI })}
        </Button>

        <Button
          disabled={!removable}
          size='sm'
          variant='link'
          className='text-reset'
          onClick={e =>
            handleRemoveClick(e.currentTarget as HTMLButtonElement, lang)
          }
        >
          <FontAwesomeIcon icon={faTrash} fixedWidth />
          <span aria-hidden='true' className='sr-only'>
            {t('remove_voice')}
          </span>
        </Button>
      </div>
      {expanded && (
        <ListGroup className='w-100 my-3'>
          {voiceList.map(voice => (
            <VoiceOption
              key={voice.voiceURI}
              voice={voice}
              greetingLang={composeGreeting(lang, '') && lang}
              onSelect={voiceURI => {
                changeVoice(lang, voiceURI)
                setExpanded(false)
                toggle.current?.focus()
              }}
            />
          ))}
        </ListGroup>
      )}
    </ListGroup.Item>
  )
}

const VoiceOption = ({
  voice,
  greetingLang,
  onSelect
}: {
  voice: Voice
  greetingLang?: string
  onSelect: (voiceURI: string) => void
}) => {
  const { gender, name, voiceURI, desc, lang, premium } = voice
  const isFast = !premium

  // const speaker = useRef<HTMLElement>(null)
  const { setLastError } = useContext(ErrorContext)

  return (
    <ListGroup.Item
      key={voiceURI}
      action
      as='div'
      tabIndex={0}
      onClick={() => {
        onSelect(voiceURI)
      }}
      onKeyDown={e => {
        const { code } = e
        if (code === 'Space' || code === 'Enter') {
          e.preventDefault()
          e.stopPropagation()
          onSelect(voiceURI)
        }
      }}
      className='d-flex justify-content-between align-items-center flex-wrap p-2 pl-3'
      style={{ cursor: 'pointer' }}
      data-lang={lang}
    >
      <div className='flex-grow-1'>
        {name}
        {!!desc && ` (${desc})`}
        {!!gender && (
          <span className='ml-1'>{gender === 'female' ? '♀' : '♂'}</span>
        )}
        {isFast && (
          <FontAwesomeIcon
            icon={faBolt}
            fixedWidth
            size='sm'
            className='text-muted'
          />
        )}
      </div>
      <FlagOrNull lang={lang} className='mx-1' />
      {!!greetingLang && (
        <Button
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            const greeting = composeGreeting(greetingLang, name)
            if (greeting) {
              tts.speak(greeting, { voiceURI })
            } else {
              setLastError(new Error('Error testing ' + name))
            }
          }}
          onKeyDown={e => e.stopPropagation()}
          variant='link'
          size='sm'
          className='p-1 text-reset'
        >
          <FontAwesomeIcon
            icon={faVolumeUp}
            fixedWidth
            style={{ pointerEvents: 'none' }}
          />
        </Button>
      )}
    </ListGroup.Item>
  )
}

export default Voices
